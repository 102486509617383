
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.clientcabin-blog::v-deep {
    background-color: $white;
    color: $black;

    a {
        display: inline-block;
    }
}
